import { Image } from '@pata-app/core';
import { WidthContainer } from '@pata-app/ui-document';

import React from 'react';

import Icon from '../icon/icon';
import { FollowUsContainer, LinkContainer, SocialLinksContainer } from './followUs.styled';

const SOCIAL_LINKS = [
  {
    link: 'https://www.instagram.com/patapet_uk',
    id: 'instagram',
    icon: 'Instagram',
  },
  {
    link: 'https://www.facebook.com/patapetuk',
    id: 'facebook',
    icon: 'Facebook',
  },
  {
    link: 'https://www.linkedin.com/company/pata-pet',
    id: 'linkedin',
    icon: 'LinkedinOutline',
  },
];

export function FollowUs() {
  return (
    <FollowUsContainer>
      <Image
        height={200}
        width={200}
        src={`${process.env.CDN_BASE_URL}/images/image-dog-biscuit.png`}
        alt="Dog biscuit"
      />
      <WidthContainer noBackground>
        <h3>Follow us on social media!</h3>
        <p>Stay in the loop with the latest updates and more</p>

        <SocialLinksContainer>
          {SOCIAL_LINKS.map((link) => (
            <LinkContainer key={link.id} href={link.link}>
              <Icon size="36px" type={link.icon} />
            </LinkContainer>
          ))}
        </SocialLinksContainer>
      </WidthContainer>
    </FollowUsContainer>
  );
}
