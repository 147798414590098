import { Image } from '@pata-app/core';
import { WidthContainer } from '@pata-app/ui-document';
import { Button } from '@pata-app/ui-form';

import React from 'react';

import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import { ActionContainer, HeroContent, HeroOverviewContainer, HeroSectionContainer } from './heroSection.styled';

const HeroSection = () => {
  const handleSignUpClick = () => {
    window.location.href = `${process.env.PARTNERS_BASE_URL}/sign-up`;
  };

  const handleBookDemo = () => {
    window.location.href = `https://meetings.hubspot.com/pata/demo`;
  };

  return (
    <HeroSectionContainer>
      <Image
        src={`${process.env.CDN_BASE_URL}/images/image-grooming-hero-image.webp`}
        alt="Preview of Pata Partner app on iPad"
        height={2000}
        width={2000}
      />

      <WidthContainer noBackground>
        <HeroOverviewContainer>
          <HeroContent>
            <TextWithHighlight>
              <h1>
                Manage Your Pet Grooming Business With <span>Pata All-in-One Platform</span>
              </h1>
            </TextWithHighlight>
            <p>
              The <strong>subscription-free pet grooming booking platform </strong>
              designed to simplify scheduling, payments, and client communication.
              <br />
            </p>
            <p>Focus on what you love – caring for pets; we'll handle the rest.</p>

            <ActionContainer>
              <Button onClick={handleSignUpClick}>Join Pata</Button>
              <Button buttonType="secondary" onClick={handleBookDemo}>
                Book a demo
              </Button>
            </ActionContainer>
          </HeroContent>
          <Image
            src={`${process.env.CDN_BASE_URL}/images/image-grooming-hero-image.webp`}
            alt="Preview of Pata Partner app on iPad"
            height={2000}
            width={2000}
          />
        </HeroOverviewContainer>
      </WidthContainer>
    </HeroSectionContainer>
  );
};

export default HeroSection;
