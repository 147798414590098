import styled from 'styled-components';

export const HeroSectionContainer = styled.section`
  position: relative;

  background: ${({ theme }) => theme.core.colors.greyscale.white};
  height: 850px;

  > img {
    display: none;
  }

  > div {
    position: relative;

    height: 100%;

    margin: auto;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    display: flex;

    overflow: hidden;

    height: 600px;

    img {
      display: block;

      position: absolute;

      bottom: 0;
      height: 100%;
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    img {
      position: absolute;

      bottom: initial;
    }
  }
  @media ${({ theme }) => `${theme.core.breakpoints.xl}`} {
    img {
      position: absolute;

      bottom: initial;
    }
  }
`;

export const HeroOverviewContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;

  > img {
    width: 100%;
    height: 300px;
    margin-bottom: ${({ theme }) => theme.core.sizing[4]}px;
    object-fit: cover;
    object-position: right;
}


  }

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    > img {
      display: none;
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    display: flex;

    width: 60%;

    /* margin: ${({ theme }) => theme.core.sizing[2]}px; */
    margin-bottom: 0;
    /* margin: 0; */
    > img {
      display: none;
    }
  }
`;

export const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[3]}px;

  border-radius: ${({ theme }) => theme.core.border.element.radius}px
    ${({ theme }) => theme.core.border.element.radius}px 0 0;

  color: ${({ theme }) => theme.core.colors.greyscale.black};

  padding: ${({ theme }) => theme.core.sizing[4]}px;

  h1 {
    margin: 0;

    font-family: ${({ theme }) => theme.core.fontFamily.jost};
    font-size: 40px;

    span {
      color: ${({ theme }) => theme.core.colors.primary.green.medium};
    }
  }

  h2 {
    margin: 0;

    font-size: ${({ theme }) => theme.core.fontSizes.l}px;
  }

  > p {
    margin: 0;
    max-width: 600px;
    font-size: ${({ theme }) => theme.core.fontSizes.l}px;

    font-family: ${({ theme }) => theme.core.fontFamily.openSans};

    padding-bottom: ${({ theme }) => theme.core.sizing[2]}px;

    strong {
      font-family: ${({ theme }) => theme.core.fontFamily.openSans};
    }

    /* color: ${({ theme }) => theme.core.colors.greyscale.white}; */
  }

  button {
    cursor: pointer;
    min-width: 150px;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    max-width: 850px;
    min-width: 850px;

    margin-left: -40px;
    padding: ${({ theme }) => theme.core.sizing[5]}px;
    padding-bottom: ${({ theme }) => theme.core.sizing[5]}px;

    h1 {
      font-size: 45px;
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.xl}`} {
    padding-bottom: 70px;
  }
`;

export const HighlightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  background: ${({ theme }) => theme.core.colors.greyscale.light};

  h3 {
    margin: 0;

    font-family: ${({ theme }) => theme.core.fontFamily.jost};
    font-size: ${({ theme }) => theme.core.fontSizes.l}px;

    color: ${({ theme }) => theme.core.colors.primary.green.medium};
  }

  p {
    margin: 0;
  }

  > span {
    align-self: flex-end;

    strong {
      font-size: ${({ theme }) => theme.core.fontSizes.xl}px;
    }
  }
  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    padding: ${({ theme }) => theme.core.sizing[2]}px;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-self: flex-start;
  gap: ${({ theme }) => theme.core.sizing[2]}px;
`;
