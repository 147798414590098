import React from 'react';
import { Helmet } from 'react-helmet';

import AppWrapper from '../../components/appWrapper/appWrapper';
import { FollowUs } from '../../components/followUs/followUs';
import useScrollToLocation from '../../hooks/useScrollToLocation';
import CharityPartners from '../home/components/charityPartners/charityPartners';
import BusinessBenefits from './components/businessBenefits/businessBenefits';
import { BusinessCategories } from './components/businessCategories/businessCategories';
import { CallToAction } from './components/callToAction/callToAction';
import Faqs from './components/faqs/faqs';
import HeroSection from './components/heroSection/heroSection';
import { PataPay } from './components/pataPay/pataPay';
import { SignUpProcess } from './components/signUpProcess/signUpProcess';
import { PaidFeatures, Testimoninal } from './components/testimoninal/testimoninal';
import { TopFeatures } from './components/topFeatures/topFeatures';

export const BusinessGroomer = () => {
  useScrollToLocation();

  return (
    <>
      <Helmet>
        <title>Pata for Pet Grooming Businesses | Free Booking Platform for Pet Grooming Businesses</title>
        <meta
          name="description"
          content="Streamline your pet business with Pata's subscription-free booking platform. Manage admin, attract new clients, and grow your business — sign up today!"
        />
      </Helmet>
      <AppWrapper>
        <HeroSection />

        <TopFeatures />

        <PataPay />

        <BusinessBenefits />
        <Testimoninal />
        <SignUpProcess />

        <Faqs />
        <FollowUs />
      </AppWrapper>
    </>
  );
};
