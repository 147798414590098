import styled from 'styled-components';

export const FollowUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 ${({ theme }) => theme.core.sizing[2]}px;

  height: 500px;

  background: #8ec6b9;
  color: ${({ theme }) => theme.core.colors.greyscale.white};

  p,
  h3 {
    text-align: center;
    margin: 0;
  }

  h3 {
    font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.core.sizing[4]}px;
  }

  img {
    margin-top: -75px;
  }
`;

export const SocialLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.core.sizing[2]}px;
`;

export const LinkContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.core.colors.greyscale.white};
  }
`;
