import CalendarPerson from './calendar-person.svg';
import CurrentLocation from './current-location.svg';
import Desktop from './desktop.svg';
import Facebook from './facebook.svg';
import Instagram from './instagram.svg';
import LinkedinOutline from './linkedin-outline.svg';
import Linkedin from './linkedin.svg';
import Loader from './loader.svg';
import Megaphone from './megaphone.svg';
import Membership from './membership.svg';
import PawOutline from './paw-outline.svg';
import Paw from './paw.svg';
import PinLight from './pin-light.svg';
import TextMessage from './text-message.svg';
import Twitter from './twitter.svg';
import Youtube from './youtube.svg';

const icons: { [key: string]: React.FC } = {
  CurrentLocation,
  Facebook,
  Instagram,
  Linkedin,
  LinkedinOutline,
  Loader,
  Paw,
  PawOutline,
  PinLight,
  Twitter,
  Youtube,
  Desktop,
  Megaphone,
  TextMessage,
  CalendarPerson,
  Membership,
};

export default icons;
