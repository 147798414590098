import { Image } from '@pata-app/core';
import { WidthContainer } from '@pata-app/ui-document';
import { Button } from '@pata-app/ui-form';

import React from 'react';

import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import { businessBenefits } from './pataPay.config';
import {
  ActionContainer,
  BusinessBenefit,
  BusinessBenefitsContainer,
  BusinessBenefitsContent,
  UnlimitedContainer,
  UnlimitedLabel,
} from './pataPay.styled';

export const PataPay = () => {
  const handleSignUpClick = () => {
    window.location.href = `${process.env.PARTNERS_BASE_URL}/sign-up`;
  };

  const handleBookDemo = () => {
    window.location.href = `https://meetings.hubspot.com/pata/demo`;
  };

  return (
    <BusinessBenefitsContainer>
      <WidthContainer noBackground>
        <Image
          src={`${process.env.CDN_BASE_URL}/images/image-terminal-with-pata.webp`}
          alt="Happy dog with tongue hanging out"
          height={1500}
          width={2500}
        />
        <BusinessBenefitsContent>
          <h2>
            Offer <span>more payment methods </span>
            <br />
            with PataPay
          </h2>

          <p>Offer secure and convenient payment options for your clients with PataPay.</p>
          <p>From online credit card payments to on-site transactions with the card reader, we’ve got you covered!</p>
          <p>
            Secure deposits, reduce cancellations, and make the payment process as fast and simple as possible. Learn
            more here.
          </p>

          {/* <ActionContainer>
            <Button onClick={handleSignUpClick}>Sign up today</Button>
            <Button buttonType="secondary" onClick={handleBookDemo}>
              Book a demo
            </Button>
          </ActionContainer> */}
        </BusinessBenefitsContent>
      </WidthContainer>
    </BusinessBenefitsContainer>
  );
};
