export const TOP_FEATURES_TITLES_CONFIG = [
  {
    title: 'Streamline your business admin',
    description:
      'Cut your time spent on admin – manage bookings, client details, and team schedules in one centralised platform. ',
    image: `${process.env.CDN_BASE_URL}/images/image-dog-with-letters.webp`,
  },
  {
    title: 'Accept online bookings',
    description:
      'Let clients book your services directly through Pata’s marketplace, making it easier for them and stress-free for you.',
    image: `${process.env.CDN_BASE_URL}/images/image-lady-using-pata-on-laptop.webp`,
  },
  {
    title: 'Minimise no-shows',
    description:
      'Reduce last-minute cancellations & no-shows with upfront payments & automated reminders sent straight to your clients.',
    image: `${process.env.CDN_BASE_URL}/images/image-puppy-covering-face.webp`,
    action: {
      actionText: 'Book a demo',
      onClick: () => {},
      buttonType: 'secondary',
    },
  },
  {
    title: 'Easy-to-use platform',
    description:
      'Pata’s intuitive booking platform is designed with simplicity in mind, while our customer support team is always there for you.',
    image: `${process.env.CDN_BASE_URL}/images/image-lady-holding-dog-with-laptop.webp`,
  },
  {
    title: 'Find new clients in your local area',
    description:
      'List your business on Pata’s marketplace to start promoting your services to local pet owners looking for grooming services.',
    image: `${process.env.CDN_BASE_URL}/images/image-dog-in-bath.webp`,
    action: {
      actionText: 'Join Pata',
      onClick: () => {},
      buttonType: 'primary',
    },
  },
];
